import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectGrid from '../components/project_grid'

const ProjectsPage = () => (
    <Layout>
        <SEO title="Projects" />
        <h1>Personal Projects</h1>
        <p>A collection of hardware and software projects I've been working on recently</p>
        <ProjectGrid />
    </Layout>
)

export default ProjectsPage
