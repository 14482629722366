import React from 'react'

import { project_list } from '../data/project_data'
import './component-style/projects.scss'
import { Link } from 'gatsby'

const ProjectGrid = () => {
    return (
        <div className="project-grid">
            {project_list.map(project => (
                <Link to={project.page} key={project.id} className="project-link">
                    <div className="project-item">
                        <img src={project.image} alt={project.name}/>
                        <h3>{project.name}</h3>
                        <p>{project.desc}</p>
                        <p>{project.date}</p>
                    </div>
                </Link>
            ))}
        </div>
    )
}

export default ProjectGrid
