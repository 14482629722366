import ihack_img from '../images/ihack.png'
import nas_img from '../images/nas.png'

export const project_list = [
    {
        name: 'iHack G4',
        id: 0,
        desc: 'You can teach an old dome new tricks',
        status: 'Completed',
        date: 'April 2, 2020',
        image: ihack_img,
        page: '/blog/2020-04-02-ihack'
    },
    {
        name: 'Bookcase NAS',
        id: 1,
        desc: 'Put your data on the shelf',
        status: 'Completed',
        date: 'September 23, 2019',
        image: nas_img,
        page: '/blog/2019-09-23-nas'
    }
]
